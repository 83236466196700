<template>
  <VForm class="contacts__form"
          :key="dynamicKey"
          :initial-values="initialValues"
          @submit="submitForm">
    <VField type="text"
            name="name"
            :rules="'required'"
            :placeholder="$t('contactsForm.name.placeholder')"
            class="contacts__input-text" />
    <div class="contacts__form-mail-phone">
      <VField type="email"
              name="email"
              :rules="'email'"
              :placeholder="$t('contactsForm.email.placeholder')"
              class="contacts__input contacts__input-email" />
      <VField type="tel"
              name="phone"
              :rules="'required|min:18'"
              :placeholder="$t('contactsForm.phone.placeholder')"
              class="contacts__input"
              v-maska
              data-maska="+7 (###) ###-##-##" />
    </div>
    <VField v-slot="{ field }"
            name="text"
            :rules="'required|min:30|max:500'">
      <textarea v-bind="field"
                name="text"
                cols="30"
                rows="10"
                :placeholder="$t('contactsForm.text.placeholder')"
                class="contacts__input-text contacts__textarea"></textarea>
    </VField>
    <VErrorMessage v-for="fieldName in fieldNames"
                    :key="fieldName"
                    :name="fieldName"
                    as="p"
                    class="error-message"
                    v-slot="{ message }">
      {{ getErrorMessageLocalized(fieldName, message) }}
    </VErrorMessage>
    <div class="contacts__form-bottom">
      <button type="submit"
              class="btn contacts__btn">
        {{ $t('contactsForm.submitButton.text') }}
      </button>
    </div>
  </VForm>
</template>

<script setup lang="ts">
import { vMaska } from 'maska';
import type { IContactsFormSchema } from '@/types';

const { create } = useStrapi();
const eventBus = usePopupEventBus();
const i18n = useI18n();
const initialValues: IContactsFormSchema = {
  name: '',
  email: '',
  phone: '',
  text: '',
};
const fieldNames = Object.keys(initialValues) as (keyof typeof initialValues)[];
const dynamicKey = ref(1);

function getErrorMessageLocalized(fieldName: keyof typeof initialValues, errorMessage: string) {
  return errorMessage.replace(fieldName, `"${i18n.t(`contactsForm.${fieldName}.placeholder`)}"`);
}

async function submitForm(values: typeof initialValues) {
  await create('requests', values);
  eventBus.emit('open-success-popup');
  dynamicKey.value += 1;
}
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
